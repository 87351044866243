<template>
  <v-col cols="12" md="6" lg="6" offset-lg="3" offset-md="3">
    <v-card class="elevation-1">
      <v-card-title>Post A/RE</v-card-title>
      <v-card-text>
        <v-form>
          <v-text-field
            :value="activePayPeriod.end"
            outlined
            dense
            placeholder="Payroll Period"
            label="Payroll Period"
            readonly
          ></v-text-field>
          <v-btn
            color="primary"
            :loading="loading"
            @click="showConfirmDialog=true">
            Process
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>

    <snackbar
      v-if="message.length > 0"
      :show="message.length > 0"
      :text="message"
      :color="messageStatus"
      @close="message=''"
    ></snackbar>

    <confirm-dialog
      :show="showConfirmDialog"
      @yes="submit"
      @no="showConfirmDialog=false"
    >
      <template v-slot:title>Confirm</template>
      <template v-slot:message>Are you sure you want to process?</template>
    </confirm-dialog>
  </v-col>
</template>

<script>
import { ref } from '@vue/composition-api'
import get from '@/composables/get'
import crud from '@/composables/crud'
import snackbar from '@/components/SnackBar.vue'
import confirmDialog from '@/components/ConfirmDialog.vue'

export default {
  metaInfo: {
    title: 'Post A/RE',
  },
  components: {
    snackbar,
    confirmDialog,
  },
  setup() {
    const loading = ref(false)
    const activePayPeriod = ref({})
    const formLoading = ref(false)
    const error = ref('')
    const message = ref('')
    const messageStatus = ref('')
    const showConfirmDialog = ref(false)

    const {
      store,
      responseMessage,
      responseMessageStatus,
    } = crud()

    const submit = async () => {
      showConfirmDialog.value = false
      await store(
        `${process.env.VUE_APP_URI}/api/payroll/deduction-entries/post-are`,
        loading,
        { payrollPeriod: activePayPeriod.value.end },
        null,
      )
      message.value = responseMessage.value
      messageStatus.value = responseMessageStatus.value
    }

    const getPayPeriod = async () => {
      await get(`${process.env.VUE_APP_URI}/api/payroll/payroll-periods/get-active`, activePayPeriod, formLoading, error)
      if (error.value.data !== undefined) {
        message.value = error.value.data.message
        messageStatus.value = error.value.status
      }
    }

    getPayPeriod()

    return {
      store,
      submit,
      loading,
      message,
      messageStatus,
      activePayPeriod,
      showConfirmDialog,
    }
  },
}
</script>
